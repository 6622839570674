import React, {Component} from "react"
import PropTypes from "prop-types"

import {connect} from 'react-redux';
import {withAuth} from 'react-devise';
import {Link} from 'react-router-dom';
import {Button, CircularProgress, Grid, TextField} from '@material-ui/core';

import {logout} from "react-devise/lib/actions";
import {fetchWithJWT} from "./FetchWithJWT";
import HeaderTitle from "./HeaderTitle";
import {history} from "./setup";
import ShippingSettings from "./ShippingSettings";
import FrequencySettings from "./FrequencySettings";
import PasswordSettings from "./PasswordSettings";
import Typography from "@material-ui/core/Typography";
import Divider from '@material-ui/core/Divider';
import SocialNetworkSettings from "./SocialNetworkSettings"

class SettingsPage extends Component{
    constructor(props) {
        super(props);
        this.state = {
            user: null
        };

        this.frequencyLabel = {
            10: "Every Month",
            20: "Every Other Month",
            30: "Every 3 Month",
            '-100': "Pause Subscription"
        }

    }
    componentWillMount(){
        fetchWithJWT(`/account`)
            .then((data) => {this.setState({ user: data}) });
    }


    render() {
        let {currentUser, doLogout, auth: {AuthLinks}} = this.props
        //console.log(this.state.user)
        let name = currentUser.firstName.split(' ')

        return (
            <React.Fragment>
                <HeaderTitle title="Account Settings"/>
                <Grid container>
                    <Grid item xs={12} md={1}></Grid>
                    <Grid item xs={12} md={10} container spacing={4} justify={'center'} style={{padding: '0 10px'}}>
                        <Grid item xs={12} container justify={'center'}>
                            <Grid item>
                                <Button variant="contained" color="secondary" onClick={() => doLogout()} >
                                    Sign Out
                                </Button>
                            </Grid>
                        </Grid>
                        {!this.state.user && (<Grid item><CircularProgress color="secondary"/></Grid>)}
                        {this.state.user && (
                            <React.Fragment>
                                <Grid item container xs={12} spacing={2}>
                                    <Grid item container xs={12} >
                                        <Typography  variant="h5" component="h5" style={{fontWeight: 600}}>
                                            Your Information
                                        </Typography>
                                    </Grid>

                                    <Grid item container xs={12}>
                                        <TextField
                                            inputRef={this.passwordRef}
                                            variant="outlined"
                                            label="Email address"
                                            fullWidth={true}
                                            value={currentUser.email}
                                            disabled
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} container spacing={2}>
                                    <Grid item xs={12} container>
                                      <PasswordSettings />
                                    </Grid>
                                </Grid>

                                <Divider style={{width: '100%'}}/>
                                <Grid item xs={12} container spacing={2}>
                                    <Grid item xs={12} container>
                                        <Typography  variant="h5" component="h5" style={{fontWeight: 600}}>
                                            Billing
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4} container>
                                        PAYMENT INFO
                                    </Grid>

                                    <Grid item xs={8}>
                                        {this.state.user.card && (
                                            <React.Fragment>
                                            {`${this.state.user.card.brand}`}<br/>
                                            {`**** **** **** ${this.state.user.card.last4}`}<br/>
                                            {`${this.state.user.card.exp_month}/${this.state.user.card.exp_year}`}
                                            </React.Fragment>
                                        )}
                                    </Grid>

                                    <Grid item xs={12} container>
                                        <Button variant="contained" color="secondary" onClick={() => history.push('/stripe_settings')}>
                                            Update payment method
                                        </Button>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} container>
                                    <ShippingSettings user={this.state.user}/>
                                </Grid>

                                <Grid item xs={12} container>
                                    <FrequencySettings user={this.state.user}/>
                                </Grid>

                                <Divider style={{width: '100%'}}/>
                                <Grid item xs={12} container>
                                    <SocialNetworkSettings user={this.state.user}/>
                                </Grid>

                            </React.Fragment>
                        )}
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
};

const mapStateToProps = state => {
    return {
        currentUser: state.currentUser
    };
};

const mapDispatchToProps = dispatch => {
    return {
        doLogout: () => logout(dispatch)
    };
};

export default connect(mapStateToProps,mapDispatchToProps)(withAuth(SettingsPage));
